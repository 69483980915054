<template>
    <div id="game">
        <header>
            <div class="header-container">
                <div class="header-left">
                    <div class="icon-btn help" @click="helpOpened = true" title="مساعدة">
                        <img class="icon" src="/icons/help.svg" alt="مساعدة" />
                    </div>
                </div>
                <div class="game-title"><!--MORDLE-->
                    <div class="letter correct">ك</div>
                    <div class="letter incorrect">ل</div>
                    <!-- <div class="space"></div> -->
                    <div class="letter partial">م</div>
                    <div class="letter incorrect">ا</div>
                    <div class="letter correct">ت</div>
                </div> 
                <div class="header-right">
                    <div class="icon-btn stats" @click="statsOpened = true" title="احصائيات">
                        <img class="icon" src="/icons/stats.svg" alt="احصائيات" />
                    </div>
                    <div class="icon-btn settings" @click="settingsOpened = true" title="الاعدادات">
                        <img class="icon" src="/icons/settings.svg" alt="الاعدادات" />
                    </div>
                </div>
            </div>
        </header>
        <main>
            <transition name="fade">
                <div class="error" v-if="error">{{ error }}</div>
            </transition>
            <div class="grid">
                <div class="attempt" v-for="attempt, indexA in attempts" :key="indexA" :class="{ shake: error && indexA === currentAttempt - 1 }">
                    <LetterContainer 
                        :letter="attempts[indexA][indexL]" 
                        :color="results[indexA][indexL]" 
                        :placement="letter" 
                        :animate="animateLetter" 
                        :colorBlindMode="colorBlindMode"
                        v-for="letter, indexL in NB_LETTERS" 
                        :key="letter" />
                </div>
            </div>
            <div class="keyboard">
                <div class="keyboard-line" v-for="line, index in keyboard.content" :key="index">
                    <Key
                        :keyContent="key"
                        :color="getKeyColor(key)"
                        :colorBlindMode="colorBlindMode"
                        v-for="key in line"
                        :key="key"
                        :keyboardName="keyboard.name"
                        @update:handleClick="handleKeyClick($event)"
                    />
                </div>
                <div class="alef-line" name='alef-line'>
                    <Key
                        :keyContent="key"
                        :color="getKeyColor(key)"
                        :colorBlindMode="colorBlindMode"
                        v-for="key in keysalef.content"
                        :key="key"
                        :keyboardName="keyboard.name"
                        @update:handleClick="handleKeyClick($event)"
                    />
                </div>

            </div>
            <transition name="fadeup">
                <div class="help-modal" v-if="helpOpened">
                    <div class="help-modal-content">
                        <div class="close-btn" @click="helpOpened = false">
                            <img class="icon" src="/icons/close.svg" alt="اغلق" />
                        </div>
                        <h2>كيف نلعب؟</h2>
                        <div class="help-content">
                            <p>تستخدم هذه اللعبة نفس مفهوم <a href="https://www.powerlanguage.co.uk/wordle/" target="_blank">Wordle</a> تمامًا ، ولكن باللغة العربيّة.</p>
                            <p>كل يوم ، يتم اختيار كلمة مكونة من 5 أحرف عشوائيًا. عليك أن تخمنها في 6 محاولات.</p>
                            <p>في كل محاولة ، سيتغير لون أحرف الكلمة التي توصلت إليها اعتمادًا على مدى قربك من العثور عليها.</p>
                            <div class="help-exemple">
                                <div class="help-word">
                                    <div class="help-letter-container correct" :class="{ 'color-blind': colorBlindMode }">
                                        م
                                    </div>
                                    <div class="help-letter-container">
                                        ه
                                    </div>
                                    <div class="help-letter-container">
                                        ر
                                    </div>
                                    <div class="help-letter-container">
                                        ا
                                    </div>
                                    <div class="help-letter-container">
                                        ن
                                    </div>
                                </div>
                                <p>الحرف <span class="correct" :class="{ 'color-blind': colorBlindMode }">م</span> موجود في الكلمة ، في المكان المناسب. </p>
                                <div class="help-word">
                                    <div class="help-letter-container">
                                        ن
                                    </div>
                                    <div class="help-letter-container">
                                        ع
                                    </div>
                                    <div class="help-letter-container partial" :class="{ 'color-blind': colorBlindMode }">
                                        س
                                    </div>
                                    <div class="help-letter-container">
                                        ا
                                    </div>
                                    <div class="help-letter-container">
                                        ن
                                    </div>
                                </div>
                                <p>الحرف <span class="partial" :class="{ 'color-blind': colorBlindMode }">س</span> موجود في الكلمة ولكن في المكان الخطأ. </p>
                                <div class="help-word">
                                    <div class="help-letter-container">
                                        ت
                                    </div>
                                    <div class="help-letter-container">
                                        ر
                                    </div>
                                    <div class="help-letter-container">
                                        ح
                                    </div>
                                    <div class="help-letter-container">
                                        ي
                                    </div>
                                    <div class="help-letter-container incorrect" :class="{ 'color-blind': colorBlindMode }">
                                        ل
                                    </div>
                                </div>
                                <p>الحرف <span>ل</span> غير موجود في الكلمة.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fadeup">
                <div class="endgame-modal" v-if="statsOpened">
                    <div class="endgame-modal-content" v-bind:class="{ 'finished' : finished}">
                        <div class="close-btn" @click="statsOpened = false">
                            <img class="icon" src="/icons/close.svg" alt="Fermer" />
                        </div>
                        <h2>احصائيات</h2>
                        <div class="stats">
                            <div class="stats-content">
                                <div class="stats-line">
                                    <div class="stats-item games-played">
                                        <p class="stat-item-figure">{{ userResults.nbGames }}</p>
                                        <p class="stat-item-label">الالعاب</p>
                                    </div>
                                    <div class="stats-item win-rate">
                                        <p class="stat-item-figure">{{ Math.round((userResults.nbGames > 0 ? userResults.nbWins / userResults.nbGames : 0) * 100) }}</p>
                                        <p class="stat-item-label">الانتصارات (%)</p>
                                    </div>
                                </div>
                                <div class="stats-line">
                                    <div class="stats-item current-streak">
                                        <p class="stat-item-figure">{{ userResults.currentStreak }}</p>
                                        <p class="stat-item-label">السلسلة الحالية</p>
                                    </div>
                                    <div class="stats-item current-streak">
                                        <p class="stat-item-figure">{{ userResults.bestStreak }}</p>
                                        <p class="stat-item-label">افضل سلسلة</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2>الاداء</h2>
                        <div class="graph">
                            <div class="graph-content">
                                <div class="graph-item" v-for="attempt in NB_ATTEMPTS + 1" :key="attempt">
                                    <div class="attempt-number" v-if="attempt <= NB_ATTEMPTS">{{ attempt }}</div>
                                    <div class="attempt-skull" v-else>
                                        <img class="icon" src="/icons/skull.svg" alt="Mort" />
                                    </div>
                                    <div class="attempt-stat">
                                        <div class="attempt-bar" :class="{ best: finished && attempt === currentAttempt}" :style="{ width: `${getAttemptStatPercent(attempt)}%`}">{{ getAttemptStat(attempt) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="soluce" v-if="finished">
                            <div class="subtitle">الكلمة كانت</div>
                            <h2>{{ wordOfTheDay }}</h2>
                            <div class="ctas">
                                <a :href="`https://www.almaany.com/ar/dict/ar-ar/${this.wordOfTheDay.toLowerCase()}`" target="_blank" class="btn definition-btn">
                                    <img class="icon" src="/icons/book.svg" />
                                    <p>المعنى</p>
                                </a>
                                <div class="btn share-btn" @click="share">
                                    <img class="icon" src="/icons/copy.svg" />
                                    <p>{{resultsCopied ? 'تم النسخ!' : 'شارك'}}</p>
                                </div>
                                <a :href="`https://www.buymeacoffee.com/yazeedz`" target="_blank" class="btn definition-btn">
                                    <img class="icon" src="/icons/coffee.svg" />
                                    <p>ادعمني</p>
                                </a>
                            </div>


                        </div>
                    </div>
                    <div class="modal-footer" v-if="finished">
                        <div class="next-in">تبقى للكلمة التالية</div>
                        <div class="time">{{ countdownToNextWord }}</div>
                    </div>
                </div>
            </transition>
            <transition name="fadeup">
                <div class="settings-modal" v-if="settingsOpened">
                    <div class="settings-modal-content">
                        <div class="close-btn" @click="settingsOpened = false">
                            <img class="icon" src="/icons/close.svg" alt="Fermer" />
                        </div>
                        <h2>الاعدادات</h2>
                        <div class="settings-content">
                            <!-- <div class="settings-item setting-toggle">
                                <h3>Lien partagé</h3>
                                <div class="toggle-button" @click="sharedLink = !sharedLink" :class="{ activated: sharedLink }">
                                    <div class="toggle"></div>
                                </div>
                            </div> -->
                            <div class="settings-item setting-toggle">
                                <h3>وضع عمى الألوان</h3>
                                <div class="toggle-button" @click="colorBlindMode = !colorBlindMode" :class="{ activated: colorBlindMode }">
                                    <div class="toggle"></div>
                                </div>
                            </div>
                            <div class="settings-item setting-button">
                                <h3>لوحة المفاتيح</h3>
                                <div class="buttons">
                                    <button :class="{ selected: keyboard.name === KEYBOARD_AZERTY.name }" @click="keyboard = KEYBOARD_AZERTY">PC</button>
                                    <!-- <button :class="{ selected: keyboard.name === KEYBOARD_QWERTY.name }" @click="keyboard = KEYBOARD_QWERTY">QWERTY</button>
                                    <button :class="{ selected: keyboard.name === KEYBOARD_QWERTZ.name }" @click="keyboard = KEYBOARD_QWERTZ">QWERTZ</button> -->
                                </div>
                            </div>
                            <div class="credits" dir='ltr'>
                                <h2>Credits</h2>
                                <p>
                                    Created by <a href="https://twitter.com/lezoabi" target="_blank">@lezoabi</a> with help of family and friends.
                                </p>
                                <p>
                                    Original design by <a href="https://github.com/LouanBen/wordle-fr" target="_blank">LouanBen/wordle-fr</a>.
                                </p>
                                <p>
                                    This Arabic adaptation is inspired by the original <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank">Wordle</a> by <a href="https://twitter.com/powerlanguish" target="_blank">@powerlanguish</a>. This work is independent and is not officially affiliated with the original game.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </main>
    </div>
</template>

<script>
import * as seedrandom from 'seedrandom';
import moment from 'moment-timezone';

import LetterContainer from "./grid/LetterContainer.vue";
import Key from "./keyboard/Key.vue";
import words from "../assets/json/drawable-words.json";
import playableWords from "../assets/json/playable-words.json";

moment.locale('ar')
moment.tz.setDefault('Asia/Jerusalem')

const NB_LETTERS = 5;
const NB_ATTEMPTS = 6;
const KEYS_ALEF = {
    name: 'azerty_alef',
    content: ['آ','أ','إ']
}
const KEYBOARD_AZERTY = {
    name: 'azerty',
    content: [
        ['ج', 'ح', 'خ', 'ه', 'ع', 'غ', 'ف', 'ق', 'ث', 'ص', 'ض'],
        ['ة', 'ك', 'م', 'ن', 'ت', 'ا', 'ل', 'ب', 'ي', 'س', 'ش'],
        ['Suppr', 'و', 'ر', 'ز', 'د','ذ', 'ط' ,'ظ', 'ء', 'Entrer'],
    ],
};
// const KEYBOARD_AZERTY = {
//     name: 'azerty',
//     content: [
//         ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
//         ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
//         ['Entrer', 'W', 'X', 'C', 'V', 'B', 'N', 'Suppr'],
//     ],
// };

const KEYBOARD_QWERTY = {
    name: 'qwerty',
    content: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['Entrer', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'Suppr'],
    ],
}
const KEYBOARD_QWERTZ = {
    name: 'qwertz',
    content: [
        ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['Entrer', 'Y', 'X', 'C', 'V', 'B', 'N', 'M', 'Suppr'],
    ]
};

export default {
    name: 'Game',
    components: {
        LetterContainer,
        Key,
    },
    data() {
        return {
            seedrandom,
            NB_LETTERS,
            NB_ATTEMPTS,
            KEYBOARD_AZERTY,
            KEYBOARD_QWERTY,
            KEYBOARD_QWERTZ,
            keyboard: KEYBOARD_AZERTY,
            keysalef: KEYS_ALEF,
            today: moment(),
            words,
            attempts: [],
            results: [],
            currentAttempt: 1,
            countdownToNextWord: '',
            wordOfTheDay: '',
            error: '',
            correctLetters: [],
            partialLetters: [],
            incorrectLetters: [],
            won: false,
            finished: false,
            statsOpened: false,
            settingsOpened: false,
            helpOpened: false,
            colorBlindMode: false,
            sharedLink: true,
            animateLetter: true,
            bestAttemptPercent: 0,
            resultsCopied: false,
            userResults: {
                nbGames: 0,
                nbWins: 0,
                currentStreak: 0,
                bestStreak: 0,
                games: [],
            },
            // {
            //     nbGames: 0,
            //     nbWins: 0,
            //     currentStreak: 0,
            //     bestStreak: 0,
            //     games: [
            //         {
            //              date: '',
            //              won: false,
            //              nbAttempts: 6,
            //          }
            //     ],
            // }
        }
    },
    mounted() {

        // Update timer to next word
        setInterval((function () {
            let duration = moment.duration(this.today.clone().endOf('day').diff(moment()))
            this.countdownToNextWord = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
            if (duration.as('milliseconds') < 0)
                this.countdownToNextWord = '00:00:00'

        }).bind(this), 1000)
        const allowed =  ["ء","ا", "ب", "ت", "ة", "ث", "ج", "ح", "خ", "د", "ذ", "ر", "ز", "س", "ش", "ص", "ض", "ط", "ظ", "ع", "غ", "ف", "ق", "ك", "ل", "م", "ن", "ه", "و", "ي"]
        // const allowed =  ["ا", "ب", "ت", "ة", "ث", "ج", "ح", "خ", "د", "ذ", "ر", "ز", "س", "ش", "ص", "ض", "ط", "ظ", "ع", "غ", "ف", "ق", "ك", "ل", "م", "ن", "ه", "و", "ى", "ي", "ء", "آ", "أ", "ؤ", "إ", "ئ"]
        window.addEventListener('keydown', event => {
            if (allowed.includes(event.key.toUpperCase())) {
                this.handleKeyClick(event.key.toUpperCase());
            } else if (event.key === 'Enter') {
                this.handleKeyClick('Entrer');
            } else if (event.key === 'Backspace') {
                this.handleKeyClick('Suppr');
            }
        });

        for (let i = 0; i < NB_ATTEMPTS; i++) {
            this.attempts.push([]);
            this.results.push(new Array(5));
        }
        this.getWordOfTheDay();
        this.getSavedData();

        if (localStorage.getItem('sharedLink')) {
            this.sharedLink = JSON.parse(localStorage.getItem('sharedLink'));
        }

        if (localStorage.getItem('colorBlindMode')) {
            this.colorBlindMode = JSON.parse(localStorage.getItem('colorBlindMode'));
        }

        if (localStorage.getItem('keyboard')) {
            this.keyboard = JSON.parse(localStorage.getItem('keyboard'));
        }
    },
    watch: {
        sharedLink() {
            localStorage.setItem('sharedLink', JSON.stringify(this.sharedLink));
        },
        colorBlindMode() {
            localStorage.setItem('colorBlindMode', JSON.stringify(this.colorBlindMode));
        },
        keyboard() {
            localStorage.setItem('keyboard', JSON.stringify(this.keyboard));
        },
    },
    methods: {
        async getWordOfTheDay() {
            const formatedDate = this.today.format('YYYY-MM-DD');
            const seed = seedrandom(formatedDate);
            const random = seed();
            this.wordOfTheDay = this.words[Math.floor(random*1000)%this.words.length] 
            
            // Forcing temporaire pour éviter de changer le mot du jour de déploiement
            if (formatedDate === '2022-02-03')
                this.wordOfTheDay = 'دجاجة'
            if (formatedDate === '2022-02-04')
                this.wordOfTheDay = 'سياسة'
            if (formatedDate === '2024-04-01')
                this.wordOfTheDay = 'القدر'
            if (formatedDate === '2024-03-19')
                this.wordOfTheDay = 'رمضان'
            if (formatedDate === '2024-03-20')
                this.wordOfTheDay = 'قطايف'
            if (formatedDate === '2024-03-27')
                this.wordOfTheDay = 'كنافة'
            if (formatedDate === '2024-08-15')
                this.wordOfTheDay = 'لولوة'
            if (formatedDate === '2024-12-06')
                this.wordOfTheDay = 'تصحيح'
            if (formatedDate >= '2024-03-19' && formatedDate <= '2024-04-05')
                this.error = 'رمضان كريم';
                window.setTimeout(() => {
                    this.error = '';
                }, 3000);
        },
        getSavedData() {
            if (localStorage.getItem('lastSave')) {
                const lastSave = localStorage.getItem('lastSave');
                if (lastSave === this.today.format('YYYY-M-D')) {
                    if (localStorage.getItem('attempts')) {
                        this.attempts = JSON.parse(localStorage.getItem('attempts'));
                    }
                    if (localStorage.getItem('results')) {
                        this.results = JSON.parse(localStorage.getItem('results'));
                    }
                    if (localStorage.getItem('currentAttempt')) {
                        this.currentAttempt = JSON.parse(localStorage.getItem('currentAttempt'));
                    }
                    if (localStorage.getItem('correctLetters')) {
                        this.correctLetters = JSON.parse(localStorage.getItem('correctLetters'));
                    }
                    if (localStorage.getItem('partialLetters')) {
                        this.partialLetters = JSON.parse(localStorage.getItem('partialLetters'));
                    }
                    if (localStorage.getItem('incorrectLetters')) {
                        this.incorrectLetters = JSON.parse(localStorage.getItem('incorrectLetters'));
                    }
                    if (localStorage.getItem('won')) {
                        this.won = JSON.parse(localStorage.getItem('won'));
                    }
                    if (localStorage.getItem('finished')) {
                        this.finished = JSON.parse(localStorage.getItem('finished'));
                    }
                    if (localStorage.getItem('userResults')) {
                        this.userResults = JSON.parse(localStorage.getItem('userResults'));
                    }
                } else {
                    this.reset();
                }
            } else {
                this.reset();
            }
        },
        reset() {
            localStorage.setItem('attempts', JSON.stringify(this.attempts));
            localStorage.setItem('results', JSON.stringify(this.results));
            localStorage.setItem('currentAttempt', JSON.stringify(this.currentAttempt));
            localStorage.setItem('correctLetters', JSON.stringify(this.correctLetters));
            localStorage.setItem('partialLetters', JSON.stringify(this.partialLetters));
            localStorage.setItem('incorrectLetters', JSON.stringify(this.incorrectLetters));
            localStorage.setItem('won', JSON.stringify(this.won));
            localStorage.setItem('finished', JSON.stringify(this.finished));
            localStorage.setItem('lastSave', this.today.format('YYYY-M-D'));
            if (localStorage.getItem('userResults')) {
                this.userResults = JSON.parse(localStorage.getItem('userResults'));
            }
        },
        getKeyColor(key) {
            if (this.correctLetters.includes(key)) {
                return 'correct';
            } else if (this.partialLetters.includes(key)) {
                return 'partial';
            } else if (this.incorrectLetters.includes(key)) {
                return 'incorrect';
            } else {
                return '';
            }
        },
        handleKeyClick(key) {
            this.animateLetter = true;
            this.error = '';
            if (key === 'Entrer') {
                this.verifyWord(this.attempts[this.currentAttempt - 1]);
            } else if (key === 'Suppr') {
                if(!this.userResults.games.find((game) => {
                    return game.date === this.today.format('YYYY-M-D');
                })) {
                    this.attempts[this.currentAttempt - 1].pop();
                }
            } else if (this.attempts[this.currentAttempt - 1].length < NB_LETTERS) {
                this.attempts[this.currentAttempt - 1].push(key);
            }
            localStorage.setItem('attempts', JSON.stringify(this.attempts));
        },
        verifyWord(attempt) {
            if (attempt.length === NB_LETTERS) {
                if (this.words.includes(attempt.join('')) || playableWords.includes(attempt.join(''))) {
                    this.verifyLetters(attempt);
                } else {
                    this.error = 'هذه الكلمة ليست في القائمة';
                    window.setTimeout(() => {
                        this.error = '';
                    }, 1000);
                }
            } else {
                this.error = 'الرجاء إدخال كلمة مكونة من ' + NB_LETTERS + ' أحرف';
                window.setTimeout(() => {
                    this.error = '';
                }, 1000);
            }
        },
        verifyLetters(attempt) {
            let wordToGuess = this.wordOfTheDay.split('');
            let currentResult = this.results[this.currentAttempt - 1];
            
            attempt.forEach((letter, index) => {
                if (wordToGuess[index] === letter) {
                    currentResult[index] = 'correct';
                    wordToGuess[index] = '*';
                    if (!this.correctLetters.includes(letter)) {
                        this.correctLetters.push(letter);
                    }
                }
            });

            attempt.forEach((letter, index) => {
                if (currentResult[index] !== 'correct') {
                    if (wordToGuess.includes(letter)) {
                        let otherIndex = wordToGuess.indexOf(letter);
                        currentResult[index] = 'partial';
                        wordToGuess[otherIndex] = '*';
                        if (!this.partialLetters.includes(letter)) {
                            this.partialLetters.push(letter);
                        }
                    } else {
                        currentResult[index] = 'incorrect';
                        if (!this.incorrectLetters.includes(letter)) {
                            this.incorrectLetters.push(letter);
                        }
                    }
                }
            });
            localStorage.setItem('results', JSON.stringify(this.results));
            localStorage.setItem('correctLetters', JSON.stringify(this.correctLetters));
            localStorage.setItem('partialLetters', JSON.stringify(this.partialLetters));
            localStorage.setItem('incorrectLetters', JSON.stringify(this.incorrectLetters));
            if (attempt.join('') === this.wordOfTheDay) {
                this.won = true;
                this.finished = true;
                this.computeStats();
            } else {
                this.currentAttempt++;
                if (this.currentAttempt > NB_ATTEMPTS) {
                    this.finished = true;
                    this.computeStats();
                }
            }
            localStorage.setItem('currentAttempt', JSON.stringify(this.currentAttempt));
            localStorage.setItem('won', JSON.stringify(this.won));
            localStorage.setItem('finished', JSON.stringify(this.finished));
        },
        computeStats() {
            let games = this.userResults.games;
            let todaysGame = games.find((game) => game.date === this.today.format('YYYY-M-D'));

            if(!todaysGame) {
                let yesterday = this.today.clone().subtract(1, 'day');
                let yesterdaysGame = games.find(game => game.date === yesterday.format('YYYY-M-D'));
                let isStreak = Boolean(yesterdaysGame && yesterdaysGame.won);

                this.userResults.nbGames++;

                if (this.won) {
                    this.userResults.nbWins += 1;
                    this.userResults.currentStreak = isStreak ? this.userResults.currentStreak + 1 : 1;
                } else {
                    this.userResults.currentStreak = 0;
                }

                if (this.userResults.currentStreak > this.userResults.bestStreak) {
                    this.userResults.bestStreak = this.userResults.currentStreak;
                }
                games.push({
                    date: this.today.format('YYYY-M-D'),
                    won: this.won,
                    nbAttempts: this.currentAttempt,
                });

                localStorage.setItem('userResults', JSON.stringify(this.userResults));

                // Recalcul des streaks & victoires (temporaire)
                try {
                    let expected = games.reduce((ac, game, i) => {
                        let today = moment(game.date, 'YYYY-M-D');
                        let yesterday = today.clone().subtract(1, 'day');
                        let yesterdaysGame = games.slice(i - 1, i).find(g => g.date === yesterday.format('YYYY-M-D'));
                        // console.log('parse', game.date, today.format('YYYY-M-D'), yesterday.format('YYYY-M-D'), yesterdaysGame)
                        let isStreak = Boolean(yesterdaysGame && yesterdaysGame.won);
                        ac.nbGames++;
                        if (game.won) {
                            ac.nbWins += 1;
                            ac.currentStreak = isStreak ? ac.currentStreak + 1 : 1;
                        } else {
                            ac.currentStreak = 0;
                        }

                        if (ac.currentStreak > ac.bestStreak) {
                            ac.bestStreak = ac.currentStreak;
                        }

                        return ac;
                    }, {
                        nbGames: 0,
                        nbWins: 0,
                        currentStreak: 0,
                        bestStreak: 0
                    });

                    // console.log(expected, this.userResults);
                    
                    let updated = false;
                    Object.entries(expected).filter(([k, v]) => this.userResults[k] !== v).forEach(([k, v]) => {
                        // console.log(`fixing "${k}" value, expected ${v}, got ${this.userResults[k]}`)
                        this.userResults[k] = v;
                        updated = true;
                    });

                    if (updated) {
                        localStorage.setItem('userResults', JSON.stringify(this.userResults));
                    }
                } catch (err) {
                    console.error(err);
                }

            }
            window.setTimeout(() => { this.statsOpened = true }, 2000);
        },
        getAttemptStat(attemptNumber) {
            let iteration = 0;
            this.userResults.games.forEach((game) => {
                if (game.nbAttempts === attemptNumber) {
                    iteration++;
                }
            });
            return iteration;
        },
        getAttemptStatPercent(attemptNumber) {
            if (this.getAttemptStat(attemptNumber) === 0) {
                return 0;
            }
            let attemptPercent = Math.round((this.getAttemptStat(attemptNumber) / this.userResults.nbGames) * 100);
            this.bestAttemptPercent = attemptPercent > this.bestAttemptPercent ? attemptPercent : this.bestAttemptPercent;
            return attemptPercent;
        },
        getWordID() {
            return this.today.clone().startOf('day').diff(moment("2022-01-30T00:00:00"), 'days') + 1
        },
        share() {
            const url = "https://woardle.com";
            const title = `كلمات #${this.getWordID()} ${this.currentAttempt <= NB_ATTEMPTS ? this.currentAttempt : '💀' }/${NB_ATTEMPTS}\n`;
            let schema = this.results.slice(0, this.currentAttempt).map((result) => {
                return result.map((letter) => {
                    if (letter === 'correct') {
                        return '🟩';
                    } else if (letter === 'partial') {
                        return '🟨';
                    } else {
                        return '⬛';
                    }
                }).join('');
            }).join('\n');
            const twit = "@WordleAR";
            

            let sharedContent = title + schema;

            if (this.sharedLink) {
                sharedContent = sharedContent + '\n' + url + '\n'+twit;
            }

            navigator.clipboard.writeText(sharedContent);
            this.resultsCopied = true;
        }
    }
}
</script>

<style lang="sass" scoped>
#game
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    overflow: hidden
    display: flex
    flex-direction: column
    background: #0E0E0F
    header
        width: 100%
        height: 60px
        display: flex
        justify-content: center
        background: #1D1D20
        @media (max-height: 540px)
            height: 40px
        .header-container
            max-width: 500px
            width: 100%
            height: 100%
            display: flex
            align-items: center
            justify-content: space-between
            @media (max-width: 512px)
                padding: 0 12px
                box-sizing: border-box
            .game-title
                display: flex
                align-items: center
                justify-content: center
                .letter
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    margin: 0 3px
                    font-size: 14px
                    font-weight: 700
                    border-radius: 4px
                    text-transform: uppercase
                    color: white
                    @media (max-height: 540px)
                        width: 20px
                        height: 20px
                        font-size: 12px
                        margin: 0 2px
                    @media (max-width: 320px)
                        width: 20px
                        height: 20px
                        font-size: 12px
                        margin: 0 1px
                    &.correct
                        background-color: #3EAA42
                    &.partial
                        background-color: #D3952A
                    &.incorrect
                        background-color: none
                        width: 14px
                .space
                    width: 14px
                    @media (max-width: 320px)
                        width: 10px
            .header-right
                display: flex
                width: 70px
                justify-content: space-between
                @media (max-height: 540px)
                    width: 62px
            .header-left 
                width: 70px
            .icon-btn
                display: flex
                align-items: center
                justify-content: center
                width: 32px
                height: 32px
                background-color: #3A3A3C
                border-radius: 5px
                border-bottom: 2px solid #2B2B2B
                cursor: pointer
                transition: all .3s
                @media (max-height: 540px)
                    width: 28px
                    height: 28px
                &:hover
                    background-color: #474748
                    border-color: #313131
                &:active
                    background-color: #2B2B2B
                    border-color: #2B2B2B
                .icon
                    height: 13px
    main
        max-width: 500px
        height: 95%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        box-sizing: border-box
        position: relative
        .fade-enter-active, .fade-leave-active
            transition: opacity .5s
        .fade-enter, .fade-leave-to
            opacity: 0
        .fadeup-enter-active, .fade-leave-active
            transition: opacity .5s
        .fadeup-enter, .fade-leave-to
            opacity: 0
            transform: translateY(10px)
        .error
            top: 20px
            margin-top: 6px
            position: absolute
            background: #ebebeb
            color: #121213
            padding: 0.5em 1em
            border-radius: 0.5em
            font-size: 18px
            font-weight: bold
            z-index: 10
        .grid
            margin-top: auto
            margin-bottom: auto
            .attempt
                display: flex
                animation-duration: 0.3s
                perspective: 800px
                &.shake
                    animation-name: shake
        .keyboard
            margin-bottom: 20px
            @media (max-height: 900px)
                margin-bottom: 7px
            .keyboard-line
                margin-top: 10px
                &:first-child
                    margin: 0
                @media (max-height: 540px)
                    margin-top: 4px
            .alef-line
                margin-top: -200px
                position: absolute
                margin-right: 175px
                display: none
                &:first-child
                    margin: 0
                @media (max-height: 540px)
                    margin-top: 4px
        .help-modal
            position: fixed
            display: flex
            width: 100vw
            height: 100vh
            display: flex
            justify-content: center
            align-items: center
            background: rgba(0, 0, 0, 0.7)
            top: 0
            left: 0
            z-index: 10
            .help-modal-content
                position: relative
                display: flex
                align-items: flex-start
                flex-direction: column
                max-width: 450px
                width: 90%
                max-height: 90%
                box-sizing: border-box
                padding: 24px
                background: #1D1D20
                border-radius: 8px
                overflow-y: auto
                scrollbar-width: thin
                scrollbar-color: #d2d2d280 #fff0
                &::-webkit-scrollbar
                    -webkit-appearance: none
                    width: 4px
                &::-webkit-scrollbar-thumb
                    border-radius: 4px
                    background: rgba(0, 0, 0, 0.6)
                    &:hover 
                        background: rgba(0, 0, 0, 1)
                
                .close-btn
                    position: absolute
                    top: 24px
                    left: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    background-color: #3A3A3C
                    border-radius: 5px
                    border-bottom: 2px solid #2B2B2B
                    cursor: pointer
                    transition: all .3s
                    &:hover
                        background-color: #474748
                        border-color: #313131
                        .icon
                            transform: rotate(90deg)
                    &:active
                        background-color: #2B2B2B
                        border-color: #2B2B2B
                    .icon
                        height: 10px
                        transition: all .3s
                h2
                    color: white
                    font-size: 20px
                    font-weight: 700
                    margin-bottom: 16px
                p
                    font-size: 14px
                    line-height: 1.3
                    margin-bottom: 12px
                    text-align: right
                    color: #8E8E90
                    &:last-child
                        margin-bottom: 0
                    a
                        color: white
                        text-decoration: none
                        &:hover
                            text-decoration: underline
                .help-exemple
                    .help-word
                        display: flex
                        margin-top: 24px
                        margin-bottom: 8px
                        .help-letter-container
                            width: 36px
                            height: 36px
                            border: 2px solid #646464
                            box-sizing: border-box
                            margin: 2px
                            border-radius: 4px
                            display: flex
                            align-items: center
                            justify-content: center
                            font-size: 16px
                            font-weight: bold
                            color: white
                            &.correct
                                border: none
                                background: #3EAA42
                                &.color-blind
                                    background: #F5793A
                            &.partial
                                border: none
                                background: #D3952A
                                &.color-blind
                                    background: #85C0F9
                            &.incorrect
                                border: none
                                background: #3A3A3C
                    p
                        span
                            font-weight: bold
                            &.correct
                                color: #3EAA42
                                &.color-blind
                                    color: #F5793A
                            &.partial
                                color: #D3952A
                                &.color-blind
                                    color: #85C0F9
        .endgame-modal
            position: fixed
            display: flex
            flex-direction: column
            width: 100vw
            height: 100vh
            display: flex
            justify-content: center
            align-items: center
            background: rgba(0, 0, 0, 0.7)
            top: 0
            left: 0
            z-index: 10
            .endgame-modal-content
                position: relative
                display: flex
                align-items: flex-start
                flex-direction: column
                max-width: 450px
                width: 90%
                max-height: 90%
                box-sizing: border-box
                padding: 24px
                background: #1D1D20
                border-radius: 8px
                overflow-y: auto
                scrollbar-width: thin
                scrollbar-color: #d2d2d280 #fff0
                &::-webkit-scrollbar
                    -webkit-appearance: none
                    width: 4px
                &::-webkit-scrollbar-thumb
                    border-radius: 4px
                    background: rgba(0, 0, 0, 0.6)
                    &:hover 
                        background: rgba(0, 0, 0, 1)
                &.finished
                    border-bottom-left-radius: 0
                    border-bottom-right-radius: 0
                .close-btn
                    position: absolute
                    top: 24px
                    left: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    background-color: #3A3A3C
                    border-radius: 5px
                    border-bottom: 2px solid #2B2B2B
                    cursor: pointer
                    transition: all .3s
                    &:hover
                        background-color: #474748
                        border-color: #313131
                        .icon
                            transform: rotate(90deg)
                    &:active
                        background-color: #2B2B2B
                        border-color: #2B2B2B
                    .icon
                        height: 10px
                        transition: all .3s
                h2
                    color: white
                    font-size: 20px
                    font-weight: 700
                    margin-bottom: 16px
                .stats
                    margin-bottom: 32px
                    width: 100%
                    .stats-content
                        display: flex
                        @media (max-width: 380px)
                            flex-direction: column
                        .stats-line
                            display: flex
                            flex: 1
                            &:first-child
                                margin-left: 8px
                            @media (max-width: 380px)
                                &:first-child
                                    margin-left: 0px
                                &:first-child
                                    margin-bottom: 8px
                            .stats-item
                                display: flex
                                flex: 1
                                flex-direction: column
                                align-items: center
                                justify-content: center
                                height: 80px
                                margin-left: 8px
                                background-color: #0E0E0F
                                border-radius: 6px
                                &:last-child
                                    margin-left: 0
                                .stat-item-figure
                                    font-size: 28px
                                    font-weight: bold
                                    color: #3EAA42
                                .stat-item-label
                                    display: flex
                                    align-items: center
                                    height: 30px
                                    font-size: 12px
                                    color: white
                                    margin: 0 8px
                .graph
                    width: 100%
                    display: flex
                    flex-direction: column
                    .graph-content
                        width: 100%
                        display: flex
                        flex-direction: column
                        .graph-item
                            margin-top: 8px
                            width: 100%
                            display: flex
                            align-items: center
                            font-size: 12px
                            font-weight: 700
                            color: white
                            &:first-child
                                margin-top: 0
                            .attempt-number
                                width: 18px
                                display: flex
                                align-items: center
                            .attempt-skull
                                width: 18px
                                display: flex
                                align-items: center
                                .icon
                                    height: 10px
                            .attempt-stat
                                height: 17px
                                width: 100%
                                .attempt-bar
                                    height: 100%
                                    background: #3A3A3C
                                    color: white
                                    font-size: 10px
                                    font-weight: 400
                                    display: flex
                                    box-sizing: border-box
                                    padding: 0 6px
                                    justify-content: flex-end
                                    align-items: center
                                    min-width: 28px
                                    border-radius: 3px
                                    &.best
                                        background: #3EAA42
                .soluce
                    display: flex
                    flex-direction: column
                    margin-top: 16px
                    width: 100%
                    .subtitle
                        font-size: 12px
                        font-weight: 700
                        color: rgba(255, 255, 255, 0.5)
                    h2
                        margin-bottom: 8px
                    .ctas
                        display: flex
                        align-items: center
                        justify-content: center
                        .btn
                            display: flex
                            align-items: center
                            justify-content: center
                            width: 108px
                            height: 36px
                            border-radius: 5px
                            margin: 0 4px
                            color: white
                            text-decoration: none
                            font-size: 14px
                            font-weight: 700
                            cursor: pointer
                            user-select: none
                            .icon
                                height: 14px
                                margin-left: 8px
                            &.definition-btn
                                background-color: #3A3A3C
                                border-bottom: 2px solid #2B2B2B
                                &:hover
                                    background-color: #474748
                                    border-color: #313131
                                &:active
                                    background-color: #2B2B2B
                                    border-color: #2B2B2B
                            &.share-btn
                                background-color: #3EAA42
                                border-bottom: 2px solid #157D19
                                &:hover
                                    background-color: #44b848
                                    border-color: #1c9320
                                &:active
                                    background-color: #157D19
                                    border-color: #157D19
            .modal-footer
                display: flex
                flex-direction: column
                width: 100%
                justify-content: center
                align-items: center
                max-width: 450px
                width: 90%
                box-sizing: border-box
                padding: 12px
                background-color: #141415
                border-bottom-left-radius: 8px
                border-bottom-right-radius: 8px
                .next-in
                    display: flex
                    font-size: 12px
                    font-weight: 700
                    color: rgba(255, 255, 255, 0.5)
                    margin-bottom: 2px
                .time
                    font-size: 20px
                    font-weight: 700
                    color: white
        .settings-modal
            position: fixed
            display: flex
            flex-direction: column
            width: 100vw
            height: 100vh
            display: flex
            justify-content: center
            align-items: center
            background: rgba(0, 0, 0, 0.7)
            top: 0
            left: 0
            z-index: 10
            .settings-modal-content
                position: relative
                display: flex
                align-items: flex-start
                flex-direction: column
                max-width: 450px
                width: 90%
                box-sizing: border-box
                padding: 24px
                background: #1D1D20
                border-radius: 8px
                min-height: 420px
                max-height: 100%
                overflow-y: auto
                background: #121213
                border-radius: 8px
                overflow-y: auto
                scrollbar-width: thin
                scrollbar-color: #d2d2d280 #fff0
                &::-webkit-scrollbar
                    -webkit-appearance: none
                    width: 4px
                &::-webkit-scrollbar-thumb
                    border-radius: 4px
                    background: rgba(0, 0, 0, 0.6)
                    &:hover 
                        background: rgba(0, 0, 0, 1)
                .close-btn
                    position: absolute
                    top: 24px
                    left: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    background-color: #3A3A3C
                    border-radius: 5px
                    border-bottom: 2px solid #2B2B2B
                    cursor: pointer
                    transition: all .3s
                    &:hover
                        background-color: #474748
                        border-color: #313131
                        .icon
                            transform: rotate(90deg)
                    &:active
                        background-color: #2B2B2B
                        border-color: #2B2B2B
                    .icon
                        height: 10px
                        transition: all .3s
                h2
                    color: white
                    font-size: 20px
                    font-weight: 700
                    margin-bottom: 16px
                h3
                    font-size: 14px
                    font-weight: 700
                    color: #8E8E90
                .settings-content
                    width: 100%
                    .settings-item
                        width: 100%
                        padding: 8px 0
                        &.setting-toggle
                            display: flex
                            justify-content: space-between
                            align-items: center
                            .toggle-button
                                background: #3A3A3C
                                width: 46px
                                height: 25px
                                border-radius: 100px
                                box-sizing: border-box
                                cursor: pointer
                                transition: all 0.3s
                                position: relative
                                .toggle
                                    background: white
                                    width: 17px
                                    height: 17px
                                    border-radius: 100px
                                    position: absolute
                                    left: 4px
                                    top: 4px
                                    transition: all 0.3s
                                &.activated
                                    background: #3EAA42
                                    .toggle
                                        transform: translateX(21px)
                        &.setting-button
                            display: flex
                            justify-content: space-between
                            align-items: center
                            .buttons
                                background: #3A3A3C
                                border-radius: 100px
                                height: 25px
                                button
                                    font-family: Outfit, Avenir, Helvetica, Arial, sans-serif
                                    height: 25px
                                    padding: 0
                                    width: 68px
                                    background: none
                                    cursor: pointer
                                    font-size: 11px
                                    font-weight: 700
                                    color: white
                                    border: none
                                    &.selected
                                        background: #3EAA42
                                        border-radius: 100px

                                @media (max-width: 360px)
                                    button
                                        width: 60px
                                        font-size: 10px
                                @media (max-width: 330px)
                                    height: 28px // Expend hitbox on height
                                    button
                                        width: 48px
                                        height: 28px
                                        font-size: 9px
                    .credits
                        h2
                            text-align: left
                            margin-top: 32px
                        p
                            font-size: 14px
                            line-height: 1.3
                            margin-bottom: 12px
                            text-align: left
                            color: #8E8E90
                            &:last-child
                                margin-bottom: 0
                            a
                                color: white
                                text-decoration: none
                                &:hover
                                    text-decoration: underline

@keyframes shake
    0%
        transform: translateX(0)
    20%
        transform: translateX(-10px)
    40%
        transform: translateX(10px)
    60%
        transform: translateX(-10px)
    80%
        transform: translateX(10px)
    100%
        transform: translateX(0)
</style>
